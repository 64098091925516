import  React from "react"
import Layout from "../components/layout"
import { Link } from 'gatsby'
import Head from '../components/head'
import LatinPhrases from './latinphrases/App'


// markup
const LatinPhrasesDB = () => {
  return (
   <Layout >
      <Head title='Latin phrases, a searchable database' description='Fastest way to look up a Latin phrase or quote'/>
     <h1>Latin phrases, a searchable database</h1>
       
<p>
There are more Latin phrases out there than one can possible imagine. Quotes from Roman and Medieval poetry, <a href='/latinbibleverses'>Bible quotes from the Vulgate</a>, <a href='/LatinMaxims/LegalQuotes_a'>Legal Maxims</a> etc. You can even come up with your own Latin motto using our <a href='/latinmottogenerator'>Motto Generator</a>. This particular page offers a way to quickly search for a Latin phrase or its translation using the most basic form of lookup: using a single word or a partial word. For instance, if you type "bell" you will get a list of phrases containg this partial word (<i>bellum</i> is Latin for 'war', but different forms have other endings: <i>bello, belli, bellorum</i> etc.). By default, the search returns Latin phrases that match the input entered. Toggle the checkbox to go from Latin to English and back. Keep in mind that the entered text can match any part of a word found in a particular phrase, i.e. <b>orum</b> will bring up phrases that contain <i>stultorum, librorum, multorum</i> and so on. If this database gets enough use we will consider making it more flexible and customizable.
</p>
<p>
Use this database to find familiar (and unfamiliar) quotations, inspirational mottos and traditional proverbs. Always remember that you must check with someone who actually knows Latin before using any Latin phrase for a tattoo or an engraving.
</p>
<p>
     <LatinPhrases/>
     </p>
       </Layout>
  )
}

export default LatinPhrasesDB
